<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item label="卖品名称" name="mainTitle" class="ui-form__item">
						<a-input v-model:value="formState.mainTitle" placeholder="请输入卖品名称"/>
					</a-form-item>
					
					<a-form-item label="卖品状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择卖品状态" style="width: 190px;">
							<a-select-option :value="0">已启用</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="卖品类型" name="type" class="ui-form__item">
						<a-select v-model:value="formState.type" placeholder="请选择卖品类型" style="width: 190px;">
							<a-select-option :value="1">普通卖品</a-select-option>
							<a-select-option :value="5">套餐</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="卖品分类" name="classifyId">
						<div style="width: 190px;">
							<a-cascader
							    v-model:value="formState.classifyId"
							    :options="classifyList"
								:fieldNames="{
									label: 'title',
									value: 'id',
									children: 'children'
								}"
							    placeholder="请选择卖品分类"
							  />
						</div>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['snack_manage_template_add']" type="primary" @click="onAdd">新增卖品模板</a-button>
						<a-button v-permission="['snack_manage_template_delete']" :disabled="selectedRowKeys.length === 0" danger style="margin-left: 20px;" @click="onBatchDelete">批量删除</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			<a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id" :pagination="pagination" :rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }" :scroll="{ x: 1200 }">
				<template #bodyCell="{ column, record, index }">
					<template v-if="column.key === 'goods'">
						<div>
							卖品标题：<span style="color: #666;">{{ record.mainTitle }}</span>
						</div>
						<div v-if="record.classifyName">
							卖品分类: <span style="color: #999;">{{ record.classifyName }}</span>
						</div>
					</template>
					
					<template v-if="column.key === 'image'">
						<a-image :src="record.imgUrl.split(',')[0]" style="width: 60px;"></a-image>
					</template>
					
					<template v-if="column.key === 'type'">
						<a-tag v-if="record.type === 1" color="blue">普通卖品</a-tag>
						<a-tag v-if="record.type === 5" color="green">套餐卖品</a-tag>
					</template>
					
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime) }}
					</template>
					<template v-if="column.key === 'isDisabled'">
						{{ record.isDisabled ? '已禁用' : '已启用' }}
					</template>
					<template v-else-if="column.key === 'action'">
						<a-dropdown :trigger="['click', 'hover']">
							<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
							</a-button>
							<template #overlay>
								<a-menu>
									<div @click="onSee(record)">
										<a-menu-item>
											查看
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_template_edit']" @click="onEdit(record)">
										<a-menu-item>
											编辑
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_template_delete']" @click="onDelete(record)">
										<a-menu-item>
											删除
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_template_open']" v-if="record.isDisabled === 1" @click="onDisabled(record)">
										<a-menu-item>
											启用
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_template_disabled']" v-else @click="onDisabled(record)">
										<a-menu-item>
											禁用
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_template_shelves']" @click="onShelves(record)">
										<a-menu-item>
											上架到影院
										</a-menu-item>
									</div>
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</a-table>
		</a-spin>
	</div>
	<publish v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" :type="type" form="template" @back="onBack"></publish>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import publish from '@/views/snackManage/goods/publish/publish.vue';
	import {
		getGoodsAllClassifyList
	} from '@/service/modules/mall.js';
	import { getGoodsList, deleteGoods, batchDeleteGoods, pullOnShelvesGoods, pullOffShelvesGoods } from '@/service/modules/goods.js';
	export default {
		components: { Icon, publish },
		data() {
			return {
				loading: false,
				showModal: false,
				organizationList: [],
				cinemaAllList: [],
				classifyList: [],
				formState: {},
				searchData: {},
				selectedRowKeys: [],
				selectedListData: [],
				list: [],
				columns: [{
					title: '卖品图片',
					key: 'image',
					width: 130
				}, {
					title: '卖品信息',
					dataIndex: 'mainTitle',
					key: 'goods'
				}, {
					title: '卖品类型',
					key: 'type',
					width: 150
				}, {
					title: '销售价(元)',
					dataIndex: 'nowPrice',
					width: 150
				}, {
					title: '创建时间',
					key: 'createTime',
					width: 200
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 100
				},{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				isSee: false,
				id: 0,
				type: 1
			}
		},
		created() {
			this.getAllClassifyList();
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.selectedListData = [];
				this.selectedRowKeys = [];
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.searchData.classifyId && this.searchData.classifyId.length) {
					this.searchData.classifyId = this.searchData.classifyId[this.searchData.classifyId.length - 1];
				} else {
					this.searchData.classifyId = undefined;
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getGoodsList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						saleType: 2,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllClassifyList() {
				let ret = await getGoodsAllClassifyList({
					classifyType: 2
				});
				if (ret.code === 200) {
					let classifyList = ret.data.filter(item => {
						item.children = ret.data.filter(subItem => {
							return subItem.parentId === item.id;
						});
						return !item.parentId
					});
					this.classifyList = classifyList;
				}
			},
			onAdd() {
				this.type = 1;
				this.isEdit = false;
				this.isSee = false;
				this.id = 0;
				this.showModal = true;
			},
			onEdit(item){
				this.type = 1;
				this.isEdit = true;
				this.isSee = false;
				this.id = item.id;
				this.showModal = true;
			},
			onSee(item) {
				this.type = 1;
				this.isEdit = true;
				this.isSee = true;
				this.id = item.id;
				this.showModal = true;
			},
			onCopy(item) {
				this.type = 1;
				this.isEdit = false;
				this.id = item.id;
				this.showModal = true;
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该卖品模板吗？',
					onOk: async ()=> {
						let ret;
						this.loading = true;
						try {
							if (item.isDisabled) {
								ret = await pullOnShelvesGoods({
									id: item.id
								})
							} else {
								ret = await pullOffShelvesGoods({
									id: item.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗?',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await deleteGoods({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功!');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onBatchDelete() {
				this.$confirm({
					title: '提示',
					content: '确定批量删除吗?',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await batchDeleteGoods({
								ids: this.selectedRowKeys.join(',')
							});
							this.loading = false;
							if (ret.code === 200) {
								this.selectedRowKeys = [];
								this.selectedListData = [];
								this.$message.success('批量删除成功!');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onShelves(item) {
				this.type = 2;
				this.isEdit = false;
				this.isSee = false;
				this.id = item.id;
				this.showModal = true;
			},
			onSelectChange(record, selected) {
				if (selected) {
					this.selectedListData.push(JSON.parse(JSON.stringify(record)));
					this.selectedRowKeys.push(record.id);
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
